import {Route, Routes, UrlSegment} from '@angular/router';
import {inject} from '@angular/core';
import {FormService} from '@services/form.service';

export const APP_ROUTES: Routes = [
  {
    path: ':slug',
    // canMatch: [isBrowser],
    canMatch: [
      (_route: Route, _segments: Array<UrlSegment>) => {
        return !!inject(FormService).form;
      },
    ],
    loadComponent: () => import('./pages/form/form.component').then((m) => m.FormComponent),
  },
  {
    path: '**',
    canMatch: [
      (_route: Route) => {
        return !inject(FormService).form;
      },
    ],
    loadComponent: () => import('./pages/page.404/page.404.component').then((m) => m.Page404Component),
  },
];
