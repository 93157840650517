import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  PLATFORM_ID,
  provideZoneChangeDetection,
} from '@angular/core';
import {provideRouter, Router, withRouterConfig} from '@angular/router';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {APP_ROUTES} from './app.routes';
import {provideClientHydration, withEventReplay, withHttpTransferCacheOptions} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {provideHttpClient, withFetch, withInterceptors, withXsrfConfiguration} from '@angular/common/http';
import {httpApiInterceptor} from '@interceptors/http-api.interceptor';
import {appInitializerFactory} from '@utils/app-initializer.factory';
import {FormService} from '@services/form.service';
import {PlatformLocation} from '@angular/common';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    // provideHttpClient(withInterceptors([httpApiInterceptor]), withFetch()),
    provideHttpClient(
      withInterceptors([httpApiInterceptor]),
      withFetch()
      // fetch("https://api.fbfast-local.ua/public-api/find/9d702385-5752-4a77-abb2-5f89ba477f85", {});
      // withXsrfConfiguration({
      //   cookieName: 'XSRF-TOKEN',
      //   headerName: 'X-CSRF-TOKEN',
      // })
    ),
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(APP_ROUTES, withRouterConfig({paramsInheritanceStrategy: 'always'})),
    importProvidersFrom(FormsModule),
    importProvidersFrom(ReactiveFormsModule),
    provideClientHydration(
      withEventReplay(), // use hydration with NG 18 event replay
      withHttpTransferCacheOptions({
        includeHeaders: ['X-Requested-With', 'ngsw-bypass', 'XSRF-TOKEN'],
        includePostRequests: false,
        includeRequestsWithAuthHeaders: false,
      })
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      multi: true,
      deps: [FormService, PlatformLocation, Router, PLATFORM_ID],
    },
  ],
};
