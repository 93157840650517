/**
 * DO NOT EDIT!
 * THIS IS AUTOMATICALLY GENERATED FILE
 */
/* eslint-disable */

export const SVG_BUILD_VERSION = "0.0.2.4457935"

export type TSvg = 'fc.city'
  | 'fc.content'
  | 'fc.country'
  | 'fc.custom-validation'
  | 'fc.date'
  | 'fc.divider'
  | 'fc.dropdown'
  | 'fc.email'
  | 'fc.email-verification'
  | 'fc.embed'
  | 'fc.file'
  | 'fc.hidden'
  | 'fc.like'
  | 'fc.linked-record'
  | 'fc.long-text'
  | 'fc.lookup'
  | 'fc.matrix'
  | 'fc.multiple-choice'
  | 'fc.new-page'
  | 'fc.number'
  | 'fc.phone'
  | 'fc.product'
  | 'fc.rating'
  | 'fc.repeating-section'
  | 'fc.score'
  | 'fc.signature'
  | 'fc.single-choice'
  | 'fc.table'
  | 'fc.term-conditions'
  | 'fc.text'
  | 'fc.time'
  | 'fc.user-assign'
  | 'fc.variable'
  | 'fc.video'
  | 'fc.website'
  | 'fc.yes-no'
  | 'ri.arrow-down-s-line'
  | 'ri.arrow-down-wide-line'
  | 'ri.arrow-left-s-line'
  | 'ri.arrow-left-wide-line'
  | 'ri.arrow-right-s-line'
  | 'ri.arrow-right-wide-line'
  | 'ri.arrow-up-s-line'
  | 'ri.arrow-up-wide-line'
  | 'ri.bar-chart-grouped-line'
  | 'ri.bar-chart-line'
  | 'ri.calendar-schedule-line'
  | 'ri.code-view'
  | 'ri.donut-chart-line'
  | 'ri.draggable'
  | 'ri.earth-line'
  | 'ri.external-link-line'
  | 'ri.eye-line'
  | 'ri.global-line'
  | 'ri.key-2-line'
  | 'ri.key-line'
  | 'ri.line-chart-line'
  | 'ri.link'
  | 'ri.link-unlink'
  | 'ri.links-line'
  | 'ri.list-check'
  | 'ri.list-check-2'
  | 'ri.list-check-3'
  | 'ri.list-ordered'
  | 'ri.list-ordered-2'
  | 'ri.list-radio'
  | 'ri.list-settings-line'
  | 'ri.list-view'
  | 'ri.lock-line'
  | 'ri.lock-password-line'
  | 'ri.mail-send-line'
  | 'ri.more-2-line'
  | 'ri.more-line'
  | 'ri.pages-line'
  | 'ri.pie-chart-line'
  | 'ri.send-plane-2-line'
  | 'ri.send-plane-line'
  | 'ri.share-line'
  | 'ri.sticky-note-add-line'
  | 'ri.sticky-note-line'
  | 'ri.survey-line'
  | 'ri.team-line'
  | 'ri.time-zone-line'
  | 'ri.user-add-line'
  | 'ri.user-community-line'
  | 'ri.user-line'
  | 'ri.user-minus-line'
  | 'star.bx-bone'
  | 'star.bx-bulb'
  | 'star.bxs-flame'
  | 'star.bxs-flask'
  | 'star.cil-badge'
  | 'star.cil-bolt'
  | 'star.cil-bolt-circle'
  | 'star.cil-lightbulb'
  | 'star.flag'
  | 'star.flag-fill'
  | 'star.heart'
  | 'star.lemon'
  | 'star.lightbulb'
  | 'star.lightbulb-fill'
  | 'star.lightbulb-off'
  | 'star.lightbulb-off-outline'
  | 'star.lightbulb-on'
  | 'star.lightbulb-on-outline'
  | 'star.lightbulb-outline'
  | 'star.lightning'
  | 'star.lightning-bolt'
  | 'star.lightning-bolt-outline'
  | 'star.lightning-fill'
  | 'star.shield-check'
  | 'star.shield-check-outline'
  | 'star.star'
  | 'star.sunny'
  | 'star.sunny-outline';


export type TSvgOrString  = TSvg | string;

export type TSvgOrNever  = TSvg | never;
